import restaurant from "./images/rest.png";
import biserica from "./images/bis.jpg";
import img_card from "./images/7.png";
import imgheaderrestaurant from "./images/14.jpg";
import imgheader from "./images/img6.jpg";
import imgheaderm from "./images/img6.jpg";
import img2 from "./images/14.jpg";
import imgheadermiini from "./images/4.jpg";
import imgheadermiinimb from "./images/4.jpg";

{/*valeriacosarca@gmail.com*/}

const data = {
    introData: [{
        title: "Invitatie Online",
        mire: "Ion",
        mireasa: "Veronica",
        data: "7 Octombrie 2023",
        data_confirmare: "",
        savedata: "~ Salvează Data ~",
        imgdesktop: imgheader,
        imgmobil: imgheaderm,
        email: "ionr450@gmail.com", 
       tel: "+393512268905",
       phone: "tel:+393512268905",
       viber: "viber://chat?number=%2B393512268905",
       whatsapp: "https://wa.me/+393512268905",
       messenger: "https://www.messenger.com/t/",
       tel1: "+373xxx",
       phone1: "tel:+373xxx",
       viber1: "viber://chat?number=%2B373xxx",
       whatsapp1: "https://wa.me/+373xxx",
       messenger1: "https://www.messenger.com/t/iurii.denis",
    }],
    cardData: [
        {
            id: 1,
            img: biserica,
            title: "Cununia Religioasă",
            localul: "Chiesa Ortodossa della",
            name: "Natività di Santissima Vergine Maria",
            data: "7 Octombrie 2023, sâmbătă, ora 12:30",
            adress: "o e Sebastiano, 134, 35143 Padova PD, Italy",
            harta: "https://goo.gl/maps/Sp7Gs8qdCmvacVsdA",
            iframe: "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10892.148700719732!2d28.7480613!3d46.9609596!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x8a5a5f5d7adcc261!2sTesalia!5e0!3m2!1sro!2s!4v1648740787752!5m2!1sro!2s"
        }, 
        {
            id: 2,
            img: restaurant,
            title: "Locația Evenimentului",
            localul: "Restaurante",
            name: "President Events",
            data: "7 Octombrie 2023, sâmbătă, ora 17:30",
            adress: "Via dell'Artigianato, 1, 35020 Albignasego PD, Italy",
            harta: "https://goo.gl/maps/zPiDyLfUz57mq48cA",
            iframe: "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10841.768537204549!2d27.8018441!3d47.2079306!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xa5831fa218054710!2zUGFsYXR1bCBTxINyYsSDdG9yaWk!5e0!3m2!1sro!2s!4v1667940052968!5m2!1sro!2s"
        }

    ],
    blogDataNoi: [
        {
            id: 1,
            img: img_card,
            title1: "Ne căsătorim!",
            title2: "VĂ INVITĂM LA NUNTA NOASTRĂ!",
            message: "În ziua în care ne vom uni destinele, vrem să fim inconjurați de cei mai apropiați și dragi oameni nouă. În acest context avem plăcerea de a vă invita să ne fiți martori și să împărtășiți bucuria celui mai semnificativ eveniment din viața noastră.",
            ps: "",
        }

    ],
    blogDataNasii: [
        {
            id: 2,
            img: img2,
            title1: "Invitație",
            title2: "Nunta noastră!",
            message: "In ziua in care ne vom uni destinele, vrem să fim înconjurați de cei mai apropiați și dragi oameni nouă. În acest context avem plăcerea de a va invita să ne fiți martori și să împărtășiți bucuria celui mai semnificativ eveniment din viața noastră.",
             
        }
    ],
    blogDataOmSuflet: [
        {

            img: imgheaderrestaurant,
            title: "Oameni de suflet!",
            message: "Alături de care vom scrie povestea noastră.",
            nasii: "NAȘII",
            nasii_nume: "George & Maia",
        }
    ],
    blogDataDrum: [
        {
            imgdesktop: imgheadermiini,
            imgmobil: imgheadermiinimb,
            title: "De aici începe totul.",
            message: "\"Dragostea constă în dorința de a da ceea ce este al tău altuia și de a simți fericirea acestuia ca și cum ar fi a ta. \" – Emanuel Swdenborg",
        }
    ],

}

export default data;